import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import "./newSite.css";
import {
  Section,
  TitleBar,
  PlanCard,
  LoadingCard,
  Input,
  BillingEntityCard
} from "../../components";
import { addSite } from "../../stores/sites";
import { clearAlert, setAlert } from "../../stores/alerts/";
import { SiteSchema, BillingEntitySchema } from "../../utils/schemas";
import history from "../../utils/history";
import { addBillingEntity, getCreditCards, getBillingEntities } from "../../stores/billingEntities";
import { getPlans } from "../../stores/subscriptions";
import { 
  Form, 
  Button, 
  Select, 
  message, 
  Input as InputDescription, 
  Row, 
  Col, 
  Radio,
  Modal
} from "antd";
import { countries, provinces, states } from '../../consts';
import { NewBillingEntityScreen } from "..";

const { Option } = Select;

const NewSiteScreen = ({
  match,
  loading,
  billingEntities,
  newEntityID,
  plans,
  addSite,
  setAlert,
  clearAlert,
  getBillingEntities,
  getPlans,
  getCreditCards,
  addBillingEntity
}) => {
  const [disableState, setDisableState] = useState(true);
  const [renderOptions, setRenderOptions] = useState(provinces);
  const [isFormValid, setFormValid] = useState(false);
  const [form] = Form.useForm();
  //const [plan, setSelectedPlan] = useState(null);
  const [disablePostalCode, setDisablePostalCode] = useState(true);
  const [codeFormLabel, setCodeFormLabel] = useState("Postal/Zip Code");
  const [codeMask, setCodeMask] = useState();
  const [carousel, setCarousel] = useState({
    left: 0,
    center: 1,
    right: 2
  });

  const [siteInfo, setSiteInfo] = useState({
    id: match.params.siteId,
    billing_entity_id: match.params.billingEntityId
  });
  const [billing_entity_id, setBillingEntityID] = useState(null);
  const [submitForm, setSubmitForm] = useState(false);

  //Cache to store new entity ID and prevent multiple sites from being made.
  const [entityIDCache, setEntityIDCache] = useState(null);

  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  
  useEffect(() => {
    if (billingEntities) {
      if (Object.values(billingEntities).length === 1) {
        setBillingEntityID(Object.values(billingEntities)[0].id);
      } else if (Object.values(billingEntities).length  === 0) {
        setBillingEntityID(-1);
      }
    }
  }, [billingEntities]);

 
  /***************************************************************************
   *                          Callback Functions
   ***************************************************************************/
  const submitOrganization = useCallback((values) => {
    const formattedData = { ...values };
    formattedData.phone = `${formattedData.prefix}${formattedData.phone}`;
    delete formattedData.prefix;
    const { error, value } = BillingEntitySchema.validate(formattedData);

    if (error) {
      setAlert(error.message);
    } else {
      clearAlert();
      addBillingEntity(value);
      message.success('Creating billing information..');

    }
  },[addBillingEntity,clearAlert,setAlert]);

  const submitSite = useCallback(values => {
    const filteredInfo = {...values};

    for (var prop in siteInfo)
      if (siteInfo[prop] && siteInfo[prop].length > 0) filteredInfo[prop] = siteInfo[prop];

    const { error } = SiteSchema.validate(filteredInfo, {
      context: { edit: false },
    });

    if (error) {
      setAlert(error.message);
    } else {
      clearAlert();
      addSite(filteredInfo);
      message.success('Creating site..')
    }
  },[addSite, clearAlert, setAlert, siteInfo]);

  const onSubmit = useCallback((values) => {
    const filteredInfo = {...values};


    const organizationInfo = {};
    //Filter out organization fields no matter what.
      Object.entries(filteredInfo).forEach((v,i) => {
        if (v[0].includes("organization.")) {
            let index = v[0].split('.');
            organizationInfo[index[1]] = v[1];
            delete filteredInfo[v[0]];
        }
      });

    if (filteredInfo.billing_entity_id === -1) {
      submitOrganization(organizationInfo);
    }else {
      submitSite(filteredInfo);
    }
    setSubmitForm(false);
  }, [submitSite, submitOrganization]);


    /***************************************************************************
   *                                 Helper functions
   ***************************************************************************/
  const onSpin = (side) => {
    if (plans && side === 'left') {
      if (carousel.left > 0) {
        setCarousel({
          left: carousel.left - 1,
          center: carousel.left,
          right: carousel.center,
        });
      } else if (carousel.left === 0) {
        setCarousel({
          left: plans.length - 1,
          center: carousel.left,
          right: carousel.center,
        });
      }
    } else if (plans && side === 'right') {
      if (carousel.right < plans.length - 1) {
        setCarousel({
          left: carousel.center,
          center: carousel.right,
          right: carousel.right + 1,
        });
      } else if (carousel.right === plans.length - 1) {
        setCarousel({
          left: carousel.center,
          center: carousel.right,
          right: 0,
        });
      }
    }
  }

  const onSelectPlan = (plan) => {
    setSiteInfo({ ...siteInfo, plan_id: plan.id });
    //setSelectedPlan(plan);
  };

  const onFormValuesChange = () => {
    const formHasErrors = !!form.getFieldsError().filter(({ errors }) => errors.length).length;

    let siteFields = ['name','description', 'billing_entity_id'];
    let organizationFields = [ 'organization.name', 'organization.email','organization.phone'];

    let fields = siteFields.concat(billing_entity_id===-1?organizationFields:[]);

     
    if (billingEntities && billing_entity_id) {
      fields = fields.filter(value => {
        return value !== 'billing_entity_id';
      });
    }


    if (!formHasErrors && !!form.isFieldsTouched(fields, true)) {
      return setFormValid(true);
    }

    setFormValid(false);
  }

  const billingEntity = {
    'phone': '123'
  }
  
  const setCodeFieldProps = (country) => {
    
    if (country === "USA") { setCodeMask('11111');  setCodeFormLabel('Zip Code');}
    if (country === "Canada") { setCodeMask('A1A 1A1');  setCodeFormLabel('Postal Code');}

    setDisablePostalCode(false);

  };

  const handleCountryChange = () => {
    form.resetFields(['region', 'postal_code']);
    const currentCountry = form.getFieldValue('country');
    setCodeFieldProps(currentCountry);
    if (currentCountry === 'USA') {
      setRenderOptions(states)
    } else {
      setRenderOptions(provinces)
    }
    setDisableState(false)
  };

  const handleSelectOrgClick = (orgId) => {
    setBillingEntityID(orgId);
  };

   /***************************************************************************
   *                          Use effects
   ***************************************************************************/

    useEffect(() => {
      clearAlert();
      getBillingEntities();
      getCreditCards(match.params.billingEntityId);
      getPlans();
      //eslint-disable-next-line
    }, []);
  
    useEffect(() => {
      if (plans) {
        setCarousel({
          left: plans.length - 1,
          center: 1,
          right: 2
        });
        //setSelectedPlan(plans[1]);
      }
    }, [plans]);
  
    useEffect( () => {
      onFormValuesChange();
    });
  
    useEffect(() => {
      if(billingEntities !== null && Object.keys(billingEntities) === 0) {
        setBillingEntityID("-1");
      }
    }, [billingEntities]);
  
    useEffect( () => {
      if (submitForm === true) {
        onSubmit({...form.getFieldsValue(true), billing_entity_id});
        return;
      }
    }, [submitForm,billing_entity_id, form, onSubmit]);
  
      //There was a change to number of billing entities. Assume we added a new one.
      useEffect( () => {
        if (newEntityID && newEntityID !== entityIDCache) {
          setEntityIDCache(newEntityID);
          let info = form.getFieldsValue(true);
          
          //inorder to not have recusion second time through onSubmit should have a billing_enitity_id that is not -1.
          //Lets set it to the latest billing ID just added!
          info.billing_entity_id = newEntityID;
            setBillingEntityID(newEntityID);
          onSubmit(info);
        }
      }, [newEntityID, form, onSubmit, entityIDCache]);
  
   /***************************************************************************
   *                              RENDER
   ***************************************************************************/
  return (
    <>
      {billingEntity && !loading ? (
        <div className='newSiteContainer d-flex flex-column align-content-center'>
          <TitleBar
            titleText='Create Site'
            icon='icon-compass_calibration'
            classes= {
              {title: 'text-red-800'}
            }            paths={[
              {
                title: 'Sites'
              },
              {
                title: 'New'
              }
            ]}
          />
    
          <Form
              onFinish={() => setSubmitForm(true)}
              onFieldsChange={onFormValuesChange}
              layout='vertical'
              form={form}
            >
          <Section
            title='Site Information'
            className='updateSiteSection'
            sectionDivClassName="!mt-10"
          >
              <Form.Item
                name='name'
                label='Site Name'
                rules={[
                  { required: true, message: 'Please input your site name!' }
                ]}
              >
                <Input
                  name='name'
                  type='text'
                  placeholder="Site Name"
                />
              </Form.Item>
              <Form.Item
                label='Site Description'
                name='description'
                rules={[
                  { required: true, message: 'Please input your site description!' }
                ]}
              >
                <InputDescription.TextArea
                  rows='3'
                  name='description'
                  type='text'
                  placeholder="Describe your site"
                />
              </Form.Item>
              <Form.Item
                label='Domain'
                name='domain'
                        >
                <Input
                  name='domain'
                  type='text'
                  placeholder="https://example.com"
                />
              </Form.Item>
              <Form.Item
                label='Subdomain'
                name='subdomain'
                        >
                <Input
                  name='subdomain'
                  type='text'
                  placeholder=""
                />
              </Form.Item>
            
          </Section>
          <Section
            title='Site Location'
            sectionDivClassName="!mt-10"
          >
          
              <Form.Item
                name='address'
                label='Address'
              >
                <Input
                  name='address'
                  type='text'
                  placeholder='Address'
                />
              </Form.Item>
              <Row gutter={[8, 8]}>
                <Col span={8} >
                  <Form.Item
                    label='City'
                    name='city'
                  >
                    <Input
                      name='city'
                      type='text'
                      placeholder='City'
                    />
                  </Form.Item>
                </Col>
                <Col span={8} >
                  <Form.Item
                    label='Country'
                    name='country'
                  >
                    <Select
                      onChange={handleCountryChange}
                      placeholder='Country'
                    >
                      {countries.map(country => (
                        <Option key={country}>{country}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} >
                  <Form.Item
                    name='region'
                    label='Province or State'
                  >
                    <Select
                      placeholder='Province or State'
                      disabled={disableState}
                    >
                      {renderOptions.map(opt => <Option key={opt}>{opt}</Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={codeFormLabel}
                name='postal_code'
                hidden={disablePostalCode}
              >
                <Input
                  type='text'
                  name='postal_code'
                 
                  mask={codeMask}
                />
              </Form.Item>
            
          </Section>
          {plans && (
            <Section
              title='Select a Pricing Plan'
              sectionDivClassName="!mt-10"
            >
              <Row gutter={30}>
                <Col xs={24} md={1} className="price-arrows" onClick={() => onSpin('left')}>
                  <span className="price-arrows-span">
                    <i className="icon-arrow_back_ios icon" />
                  </span>
                </Col>
                <Col xs={24} md={22}>
                  <div id="PricingPlan" className="pricing-plan text-center pd-t-30 pd-b-30">
                    <Row justify="center">
                      {
                        plans &&
                        Object.values(carousel).map(i => {
                          if (plans[i]?.id)
                            return (
                              <PlanCard
                                key={plans[i]?.id}
                                plan={plans[i]}
                                active={siteInfo.plan_id === plans[i]?.id}
                                onClick={onSelectPlan}
                                extraClass={plans[i]?.extraClass}
                              />
                            );
                          return '';
                        })
                      }
                    </Row>
                  </div>
                </Col>
                <Col xs={24} md={1} className="price-arrows" onClick={() => onSpin('right')}>
                  <span className="price-arrows-span">
                    <i className="icon-arrow_forward_ios icon" />
                  </span>
                </Col>
              </Row>
            </Section>
          )}
          { billingEntities && (
            <Section title="Select Billing Information" >
              <Form.Item name="billing_entity_id">
              <Radio.Group name="billing_entity_id" className="w-full ">
                {
                  Object.values(billingEntities).map((entry, i) => {
                    return <BillingEntityCard classes={{card: `position-absolute ${billing_entity_id === entry.id?' !border-primary !border':''}`}} key={i} id={entry.id} billingEntity={entry} onClick={() => handleSelectOrgClick(entry.id)}>
                              <Radio.Button checked={billing_entity_id === entry.id}
                              style={{position: "absolute", right: "1em", bottom: "1em"}}
                              className={` ${billing_entity_id === entry.id?'ant-radio-button-wrapper-checked':''}`} value={entry.id}>Select</Radio.Button>
                    </BillingEntityCard>
                  })
                }
                <div className={`shadow-ant text-base p-4 !mb-5 ${billing_entity_id!==-1?'cursor-pointer':'!border-primary !border'}`} onClick={()=> { if(billing_entity_id!==-1) setBillingEntityID(-1)}}>
                  <div className="flex">
                    <span className="flex-1 font-bold">Create new Billing Info</span>
                    <Radio.Button onClick={() => {setBillingEntityID(-1)}} className={`flex-initial ${billing_entity_id===-1?'ant-radio-button-wrapper-checked':''}`} checked={billing_entity_id===-1} value={-1}>Select</Radio.Button>
                  </div>
                  <div className={`mt-10 ${billing_entity_id===-1?'':'hidden'}`}>
                    <NewBillingEntityScreen visible={billing_entity_id===-1} form={form} className="mt-10"
                    ></NewBillingEntityScreen>
                  </div>
                </div>
              </Radio.Group>
              </Form.Item>
              
            </Section>
          )
          }
            <Row justify='end' gutter={[8, 8]} className='pd-b-30'>
              <Col xs={24} md={6} span={8}>
              <Button
                  className='w-100'
                  type='cancel'
                  size='large'
                  onClick={() => setCancelModalVisible(true) }
                  loading={loading}
                >
                  Cancel
                </Button>
               
              </Col>
              <Col xs={24} md={6} span={8}>
              <Button
                  className='w-100 unmuted-disabled'
                  type='primary'
                  size='large'
                  loading={loading}
                  disabled={!isFormValid}
                  htmlType="submit"
                >
                  Create
                </Button>
                <Modal title="Discard changes?" visible={cancelModalVisible} onCancel={()=>setCancelModalVisible(false)} onOk={()=>{
                   history.push(
                    `/`
                  );
                }}><p>You might have unsaved changes. You will lose these changes if you continue.</p></Modal>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <LoadingCard />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alerts.alert,
  loading: state.sites.loading,
  billingEntities: state.billingEntities.billingEntities,
  plans: state.subscriptions.plans,
  creditCards: state.billingEntities.creditCards,
  newEntityID: state.billingEntities.newEntityID
});

const mapDispatchToProps = {
  addSite,
  setAlert,
  clearAlert,
  getBillingEntities,
  getCreditCards,
  getPlans,
  addBillingEntity
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSiteScreen);
